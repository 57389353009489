<template>
    <a-layout id="components-layout-demo-top-side-2">
        <a-layout-header class="header">
            <div class="logo"><span class="logo-title">
        英特信垃圾分类数据管理平台</span></div>
            <div class="user-wrapper"><span class="user-wrapper-logout" @click="exitLogin">退出登录</span></div>
        </a-layout-header>
        <a-layout>
            <a-layout-sider style="background: #fff" width="200">
                <a-menu
                        :style="{ height: '100%', borderRight: 0 }"
                        mode="vertical"
                        theme="dark"
                >
                    <a-sub-menu v-for="(item, index) in menu" v-if="!item.hidden" :key="index">
                        <span slot="title"><span>{{ item.meta.title }}</span></span>
                        <a-menu-item v-for="(subItem, subIndex) in item.children" v-show="!subItem.hidden"
                                     :key="index+'-'+subIndex" @click="goRouter(item, index, subItem, subIndex)">
                            {{ subItem.meta.title }}
                        </a-menu-item>
                    </a-sub-menu>
                </a-menu>
            </a-layout-sider>
            <a-layout style="padding: 5px;box-sizing: border-box;">
                <div style="display: flex; gap: 4px">
                    <div v-for="(it, index) in history"
                         :style="{ color: $route.path === it.path ? '#2d8cf0': '#808695', backgroundColor: $route.path === it.path ? '#e8f4ff': '' }"
                         style="padding: 4px 8px; cursor: pointer">
                        <span @click="toRoute(it)"> {{ it.meta.title }}</span>
                        <a-icon v-if="history.length > 1" type="close" @click="removeHistory(index)"/>
                    </div>
                </div>
                <a-layout-content
                        :style="{ background: '#fff', padding: '10px', margin: 0, minHeight: '280px', boxSizing: 'border-box', height: '100%', overflow: 'hidden' }"
                >
                    <!--                    <a-tabs v-if="routerStorage.length > 0" v-model="activeKey" class="ytx-layout-tab"-->
                    <!--                            style="height: 100%;"-->
                    <!--                            type="editable-card" @edit="onClose" @nextClick="callback" @prevClick="callback"-->
                    <!--                            @tabClick="goRouterQuick">-->
                    <!--                        <a-tab-pane v-for="(item, index) in routerStorage" :key="index"-->
                    <!--                                    :tab="`${JSON.parse(item).title}`" closeable="true">-->
                    <!--                            <router-view style="height: calc(100% - 40px);overflow: auto;"/>-->
                    <!--                        </a-tab-pane>-->
                    <!--                    </a-tabs>-->
                    <keep-alive>
                        <router-view v-if="$route.meta.keepAlive" style="height: calc(100%);overflow: auto;"/>
                    </keep-alive>
                    <router-view v-if="!$route.meta.keepAlive" style="height: calc(100%);overflow: auto;"/>
                </a-layout-content>
            </a-layout>
        </a-layout>
    </a-layout>
</template>
<script>
import {removeToken} from "@/utils/auth";

export default {
    name: 'layout',
    data() {
        return {
            activeKey: '',
            menu: [],
            currentMenuKey: 0,
            currentSubKey: 0,
            collapsed: false,
            topMenu: [],
            leftMenu: [],
            currentTopMenuKey: 0,
            currentLeftMenuKey: 0,
            routerStorage: [],
            history: []
        };
    },
    mounted() {
        this.currentMenuKey = localStorage.getItem('menuKey') ? localStorage.getItem('menuKey') : 0
        this.currentSubKey = localStorage.getItem('subKey') ? localStorage.getItem('subKey') : 0
        if (localStorage.getItem('routerStorage') !== '' && localStorage.getItem('routerStorage') != undefined) {
            let rs = localStorage.getItem('routerStorage').split('},') || []
            rs.forEach((item, index) => {
                if (index < rs.length - 1) {
                    rs[index] = item + '}'
                    console.log(rs[index])
                }
            })
            if (rs.length > 0)
                this.routerStorage = rs
        }
        this.getRouters()
    },
    watch: {
        $route() {
            if (!this.history.find(it => it.path === this.$route.path) && this.$route.meta.keepAlive)
                this.history.push(this.$route)
        }
    },
    methods: {
        toRoute(item) {
            this.$router.push({path: item.path})
        },
        removeHistory(index) {
            this.history.splice(index, 1)
        },
        callback(val) {
            console.log(val);
        },
        onClose(e) {
            this.closeRouter(this.routerStorage[e])
        },
        goRouterQuick(item) {
            item = this.routerStorage[item]
            this.$router.push({path: JSON.parse(item).path})
        },
        closeRouter(route) {
            this.routerStorage.forEach((item, index) => {
                if (JSON.parse(item).path == JSON.parse(route).path) {
                    this.routerStorage.splice(index, 1)
                    if (this.routerStorage.length > 0 && index > 0) {
                        this.$router.push(JSON.parse(this.routerStorage[index - 1]).path)
                        this.activeKey = index - 1
                    } else if (this.routerStorage.length > 0 && index == 0) {
                        this.$router.push(JSON.parse(this.routerStorage[index]).path)
                        this.activeKey = index

                    }
                }
            })
            localStorage.setItem('routerStorage', this.routerStorage)

        },
        openLink() {},
        goRouter(route, index, subroute, subindex) {
            //update
            this.currentSubKey = subindex
            this.currentMenuKey = index
            let path = '/management/' + this.menu[this.currentMenuKey].path + '/' + this.menu[this.currentMenuKey].children[this.currentSubKey].path
            localStorage.setItem('menuKey', this.currentMenuKey)
            localStorage.setItem('subKey', this.currentSubKey)
            localStorage.setItem('path', path)
            let ind = 0
            this.routerStorage.forEach((item, index) => {
                if (JSON.parse(item).path == path) {
                    ind++
                    this.activeKey = index
                } else {}
            })
            if (ind == 0) {
                this.routerStorage.push(JSON.stringify({title: subroute.meta.title, path: path}))
                this.activeKey = this.routerStorage.length - 1
            }
            this.$router.push({path: path})
            localStorage.setItem('routerStorage', this.routerStorage)

        },
        getRouters() {
            let menu = this.$store.getters.getRouters[7].children
            //uopdate
            menu.forEach(item => {
                if (item.children !== undefined && item.children.length > 0) {
                    this.menu.push(item)
                }
            })
            this.currentMenuKey = localStorage.getItem('menuKey') ? localStorage.getItem('menuKey') : 0
            this.currentSubKey = localStorage.getItem('subKey') ? localStorage.getItem('subKey') : 0
            let path = this.menu[this.currentMenuKey].path + '/' + this.menu[this.currentMenuKey].children[this.currentSubKey].path
            //详情页刷新保存当前带参路由
            if (this.$route.fullPath) {
                path = this.$route.fullPath
            }
            localStorage.setItem('menuKey', this.currentMenuKey)
            localStorage.setItem('subKey', this.currentSubKey)
            localStorage.setItem('path', path)
            if (this.routerStorage.length > 0) {
                this.routerStorage.forEach((item, index) => {
                    if (JSON.parse(item).path == path) {
                        this.activeKey = index
                    }
                })
            } else {
                this.routerStorage.push(JSON.stringify({
                    title: this.menu[this.currentMenuKey].children[this.currentSubKey].meta.title,
                    path: path
                }))
                this.activeKey = this.routerStorage.length - 1
            }
            this.$router.push({path: path})

        },
        exitLogin() {
            localStorage.removeItem('routerStorage')
            localStorage.removeItem('fullPath')
            localStorage.removeItem('path')
            localStorage.removeItem('subKey')
            localStorage.removeItem('menuKey')
            removeToken()
            this.$router.push({path: '/login'})

        },
    }
};
</script>

<style lang="scss">
.ant-layout {
  height: 100%;
  background: #fff;

  .ant-layout-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 44px;
    padding-left: 10px;
  }

  .ytx-layout-tab {
    .ant-tabs-content {
      height: 90%;
      overflow: auto;
    }
  }

  .ant-layout-sider {
    flex: 0 0 178px !important;
    max-width: 178px !important;
    min-width: 178px !important;
    width: 117px !important;
    background: rgb(255, 255, 255) !important;
  }
}

.ytx-router-storage {
  width: 100%;
  height: 40px;
  background: #fafafa;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.logo-title {
  display: flex;
  flex-direction: row;
  /* flex: 1; */
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1vw;
  color: #fff;

  img {
    width: 45px;
    height: 45px;
  }
}

#components-layout-demo-top-side-2 .logo {
  // width: 15%;
  // height: 31px;
  // background: rgba(255, 255, 255, 0.2);
  // margin: 16px 28px 16px 0; //增加logo图片后注释
  float: left;
}

#components-layout-demo-top-side-2 .user-wrapper {
  // width: 10%;
  // height: 31px;
  // background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
}

.user-wrapper-logout {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 17px;
  color: #fff;
}

.v-enter, .v-leave-to {
  opacity: 0;
  transition: opacity .3s;

}

.v-enter-active, .v-leave-active {
  transition: opacity .3s;

}

.ant-layout-sider-children {
  overflow: auto;
}
</style>
